import { Booking, WaitingListProposition } from '@/models'
import Actor from '@/models/enums/Actor'
import WaitingListPropositionStatus from '@/models/enums/WaitingListPropositionStatus'

export function getBookingStatusTranslationKey (booking: Booking): string {
  if (booking.bookingStatus?.isCancelled) {
    return 'registrations.table.status.cancelled'
  }
  if (booking.bookingStatus?.isCompleted) {
    return 'registrations.table.status.completed'
  }
  if (booking.bookingStatus?.isMandateSigned) {
    return 'registrations.table.status.mandate'
  }
  if (booking.bookingStatus?.isContractSigned) {
    return 'registrations.table.status.contract'
  }
  if (booking.bookingStatus?.isEmailVerified) {
    return 'registrations.table.status.email'
  }
  return 'registrations.table.status.reserved'
}

export function getSyncStatusTranslationKey (isBookingMissingCallback: boolean): string {
  return isBookingMissingCallback ? 'registrations.table.status.sync.notSynced' : 'registrations.table.status.sync.synced'
}

export function getWaitingListPropositionCancellationReasonTitleKey (proposition: WaitingListProposition) : string {
  switch (proposition.causedBy) {
  case Actor.Planner:
    return proposition.status === WaitingListPropositionStatus.Cancelled
      ? 'waitingListPropositions.table.expanded.canceledByPlannerTitle'
      : 'waitingListPropositions.table.expanded.revokedByPlannerTitle'
  default:
    return 'waitingListPropositions.table.expanded.canceledByParentTitle'
  }
}
