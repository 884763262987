<template>
  <div class="status-data d-flex flex-column">
    <div class="data-component">
      <p class="data-labels">{{ $t('waitingListPropositions.table.status.bookingStatus') }}</p>
      <p>{{ $t(getBookingStatusTranslationKey(booking)) }}</p>
    </div>
    <div class="data-component">
      <p class="data-labels">{{ $t('waitingListPropositions.table.status.sync.syncStatus') }}</p>
      <p>{{ $t(getSyncStatusTranslationKey(booking)) }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Booking, PlacementStatus } from '@/models'
import { getBookingStatusTranslationKey, getSyncStatusTranslationKey } from '@/utils/translationsUtils'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class StatusInfo extends Vue {
  @Prop({ required: true })
  booking!: Booking

  getBookingStatusTranslationKey (booking: Booking) : string {
    return getBookingStatusTranslationKey(booking)
  }

  getSyncStatusTranslationKey (booking: Booking) : string {
    const requiresWaitingListCallback = booking.locationInquiries.length > 0
    const receivedWaitingListCallback = booking.locationInquiries.every(x => x.isCallbackReceived)

    const activePlacements = booking.placements?.filter(x => x.status !== PlacementStatus.Cancelled)
    const requiresPlacementCallback = !!activePlacements && activePlacements.length > 0
    const receivedAllPlacementCallbacks = activePlacements.every(x => x.isCallbackReceived)

    const isBookingMissingCallback = (requiresWaitingListCallback && !receivedWaitingListCallback) ||
                                (requiresPlacementCallback && !receivedAllPlacementCallbacks)

    return getSyncStatusTranslationKey(isBookingMissingCallback)
  }
}
</script>
