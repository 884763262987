<template>
  <div class="d-flex flex-column data-component">
    <p class="data-labels">{{ $t('waitingListPropositions.table.columns.childNumber') }}</p>
    <p>{{ childNumber || $t('waitingListPropositions.table.childNumberUnavailable') }}</p>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ChildInfo extends Vue {
    @Prop({ required: false, default: '' })
    childNumber: string | undefined
}
</script>
