<template>
    <PartouMenu bottom left offset-y>
        <!-- open-on-hover -->
        <template v-slot:activator="{ on, attrs }">
        <PartouFloatingCircleButton v-bind="attrs" v-on="on" class="analysis-button" iconName="$vuetify.icons.partouAnalysis"/>
        </template>
        <v-list class="pt-0 pb-0">
        <v-list-item class="pa-4" :disabled="!wishedStartDateAnalysisResultId" @click="goToAvailabilityAnalysisPage(wishedStartDateAnalysisResultId)">
            <v-icon class="mr-2" v-if="wishedStartDateAnalysisResultId">$vuetify.icons.partouOpenInNew</v-icon>
            <v-icon class="mr-2" v-else>$vuetify.icons.partouOpenInNewDisabled</v-icon>
            <span class="list-item-menu">{{ $t('waitingListPropositionAnalysis.wishedStartDateAnalysisResultId') }}</span>
        </v-list-item>
        <v-list-item class="pa-4" :disabled="!offeredAnalysisResultId" @click="goToAvailabilityAnalysisPage(offeredAnalysisResultId)">
            <v-icon class="mr-2" v-if="offeredAnalysisResultId">$vuetify.icons.partouOpenInNew</v-icon>
            <v-icon class="mr-2" v-else>$vuetify.icons.partouOpenInNewDisabled</v-icon>
            <span class="list-item-menu" :class="offeredAnalysisResultId ? 'list-item-menu-text-color' : 'list-item-menu-text-color-disabled'">{{ $t('waitingListPropositionAnalysis.offeredAnalysisResultId') }}</span>
        </v-list-item>
        </v-list>
    </PartouMenu>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import PartouMenu from '@/components/PartouComponents/PartouMenu.vue'
import PartouFloatingCircleButton from '@/components/PartouComponents/PartouFloatingCircleButton.vue'
import { ROUTES } from '@/router/routes'

@Component({
  components: { PartouFloatingCircleButton, PartouMenu }
})

export default class WaitingListPropositionAnalysisResultChildrenTableMenu extends Vue {
    @Prop({ required: true })
    wishedStartDateAnalysisResultId!: string

    @Prop({ required: true })
    offeredAnalysisResultId!: string

    goToAvailabilityAnalysisPage (analysisResultId: string): void {
      const routeData = this.$router.resolve({ name: ROUTES.serviceAnalysisPreview, params: { analysisResultId } })
      window.open(routeData.href, '_blank')
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

.list-item-menu {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: $partou-primary-black-ninety !important;
}

.list-item-menu-text-color {
  color: $partou-primary-black-ninety !important;
}

.list-item-menu-text-color-disabled {
  color: $partou-primary-black-seventy !important;
}

.analysis-button {
    width: 35px;
    height: 35px;
    button span svg {
        path {
        fill: $partou-primary-black-ninety !important;
        color: $partou-primary-black-ninety !important;
        }
    }
}
</style>
