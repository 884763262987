<template>
  <div ref="cancel-reason-container" class="cancel-reason-container data-component" v-if="showCancellationReason">
    <p class="data-labels cancel-reason-container-title">{{ $t(this.cancellationReasonTitleKey) }}</p>
    <p class="cancel-reason-container-text mt-2 mb-1">{{ getCausedByReason(currentProposition.causedByReasonType) }}</p>
    <div v-if="currentProposition.causedByReasonComment" class="cancel-reason-container-comment">
      <p><v-icon class="cancel-reason-container-comment-icon mr-2" :size="16">mdi-comment-outline</v-icon></p>
      <p class="cancel-reason-container-comment-text">{{ currentProposition.causedByReasonComment }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { WaitingListProposition, Maybe } from '@/models'
import Actor from '@/models/enums/Actor'
import WaitingListPropositionStatus from '@/models/enums/WaitingListPropositionStatus'
import { getWaitingListPropositionCancellationReasonTitleKey } from '@/utils/translationsUtils'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class CancelReasonData extends Vue {
  @Prop({ required: true })
  currentProposition!: WaitingListProposition

  showCancellationReason = false

  mounted () {
    this.showCancellationReason = this.getShowCancellationReason()
  }

  get cancellationReasonTitleKey () : string {
    return getWaitingListPropositionCancellationReasonTitleKey(this.currentProposition)
  }

  getShowCancellationReason () : boolean {
    if (this.currentProposition.causedBy === Actor.Planner) {
      return this.currentProposition.status === WaitingListPropositionStatus.Cancelled || this.currentProposition.status === WaitingListPropositionStatus.Revoked
    }

    return this.currentProposition.causedBy === Actor.Parent && this.currentProposition.status === WaitingListPropositionStatus.Declined
  }

  getCausedByReason (reason: Maybe<string>): string {
    return reason || '-'
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
@import '@/styles/helpers.scss';

.cancel-reason-container {
  display: flex;
  flex-direction: column;

  .cancel-reason-container-text {
    color: $partou-primary-black-eighty;
    margin-top: 0px !important;
  }

  .cancel-reason-container-comment {
    display: flex;
    align-items: baseline;

    .cancel-reason-container-comment-icon {
      color: $partou-primary-black-seventy;
    }

    p {
      color: $partou-primary-black-eighty;
    }
  }
}
</style>
