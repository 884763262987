<template>
  <v-expansion-panel class="round-row">
    <v-expansion-panel-header class="round-header" >
      <div class="indicator" :class="round.didAllocateWaitingListSpot ? 'did-allocate-waiting-list-spots' : 'did-not-allocate-waiting-list-spots'"></div>
      <h1>{{ $t('waitingListPropositionAnalysis.round') }}: {{index + 1}}</h1>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="round-content">
      <WaitingListPropositionAnalysisResultChildrenTable :children="round.children" :round="index + 1" />
      <WaitingListPropositionAnalysisResultPropositionsTable v-if="round.didAllocateWaitingListSpot" :dayCombinations="round.dayCombinations" :possiblePropositions="round.possiblePropositions"/>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script lang="ts">
import DaysCoverage from '@/components/DaysCoverage/DaysCoverage.vue'
import { Round } from '@/models/WaitingListPropositionAnalysisResult'
import { Vue, Component, Prop } from 'vue-property-decorator'
import WaitingListPropositionAnalysisResultChildrenTable from './AnalysisChildrenTable/WaitingListPropositionAnalysisResultChildrenTable.vue'
import WaitingListPropositionAnalysisResultPropositionsTable from './PropositionsTable/WaitingListPropositionAnalysisResultPropositionsTable.vue'

@Component({
  components: { DaysCoverage, WaitingListPropositionAnalysisResultChildrenTable, WaitingListPropositionAnalysisResultPropositionsTable }
})
export default class WaitingListPropositionAnalysisResultRoundTable extends Vue {
  @Prop({ required: true })
  round!: Round

  @Prop({ required: true })
  index!: number
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

.round-row {
  .round-header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 0 10px 0 0;

    .indicator {
      width: 10px;
      height: 64px;
      margin-right: 5px;

      &.did-allocate-waiting-list-spots {
        background-color: $partou-secondary-green;
      }

      &.did-not-allocate-waiting-list-spots {
        background-color: $partou-red;
      }
    }

    h1 {
      width: 100%;
    }
  }

  .round-content {
    padding-top: 16px;
    box-shadow: inset 1px 0px 15px 1px rgba(0, 0, 0, 0.20);
  }
}
</style>
