<template>
  <div class="status-data d-flex flex-column">
    <div class="data-component">
      <p class="data-labels">{{ $t('waitingListPropositions.table.status.bookingStatus') }}</p>
      <p>{{ $t(getBookingStatusTranslationKey(currentProposition.locationInquiry.booking)) }}</p>
    </div>
    <div class="data-component">
      <p class="data-labels">{{ $t('waitingListPropositions.table.status.sync.syncStatus') }}</p>
      <p>{{ $t(getSyncStatusTranslationKey(currentProposition)) }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { WaitingListProposition, Booking, PlacementStatus } from '@/models'
import { getBookingStatusTranslationKey, getSyncStatusTranslationKey } from '@/utils/translationsUtils'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class StatusInfo extends Vue {
  @Prop({ required: true })
  currentProposition!: WaitingListProposition

  getBookingStatusTranslationKey (booking: Booking): string {
    return getBookingStatusTranslationKey(booking)
  }

  getSyncStatusTranslationKey (selectedProposition: WaitingListProposition): string {
    const activePlacement = selectedProposition.placement.status !== PlacementStatus.Cancelled
    const requiresPlacementCallback = !!activePlacement
    const receivedPlacementCallback = selectedProposition.placement.isCallbackReceived

    const isBookingMissingCallback = requiresPlacementCallback && !receivedPlacementCallback

    return getSyncStatusTranslationKey(isBookingMissingCallback)
  }
}
</script>
