<template>
  <div class="days-data data-component" v-if="booking">
    <template>
      <template v-if="booking.serviceKind == ServiceKind.SchoolCare">
        <div class="d-flex flex-column column-gap-4">
          <DayTable :serviceVarietyName="ServiceVarietyName.VSO" :reservations="reservations" :waitinglistPlaces="waitinglistPlaces" />
          <DayTable :serviceVarietyName="ServiceVarietyName.NSO" :reservations="reservations" :waitinglistPlaces="waitinglistPlaces"  />
        </div>
      </template>
      <template v-else>
        <DayTable :serviceVarietyName="ServiceVarietyName.KDV" :reservations="reservations" :waitinglistPlaces="waitinglistPlaces"  />
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ServiceVarietyName, ServiceKind, Reservation, WaitingListPlace, Booking } from '@/models'
import DayTable from '@/components/DayTable/DayTable.vue'

@Component({
  components: {
    DayTable
  }
})
export default class SelectedDaysInfo extends Vue {
  @Prop({ required: true })
  booking!: Booking

  @Prop({ required: true })
  reservations!: Reservation[]

  @Prop({ required: true })
  waitinglistPlaces!: WaitingListPlace[]

  ServiceVarietyName = ServiceVarietyName
  ServiceKind = ServiceKind
}
</script>

<style scoped>
.days-data {
  overflow: auto;
  min-width: 270px;
}
</style>
