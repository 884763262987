<template>
  <div class="d-flex flex-column data-component" v-if="showProductsInfo">
    <p class="data-labels">{{ $t('waitingListPropositions.table.expanded.products') }}</p>
    <div class="d-flex justify-space-between padding-bottom" v-for="product in products" :key="product.id">
      <div v-if="isUnsupportedProduct(product.id)">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ product.name }}
              <v-icon class="error-icon" :size="16">info_outline</v-icon>
            </span>
          </template>
          <p class="text-center white--text mt-4">{{ $t('waitingListPropositions.table.expanded.productUnsupported') }}</p>
        </v-tooltip>
      </div>
      <div v-else>
        <p>{{ product.name }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Product, ServiceProduct } from '@/models'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ProductsInfo extends Vue {
  @Prop({ required: true })
  products!: Product[]

  @Prop({ required: true })
  showProductsInfo!: boolean

  @Prop({ required: true })
  serviceProducts!: ServiceProduct[]

  isUnsupportedProduct (productId: string): boolean {
    const serviceProduct = this.serviceProducts.find(serviceProduct => serviceProduct.productId === productId)
    return !serviceProduct?.isBookable
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
@import '@/styles/helpers.scss';

.error-icon {
  color: $partou-primary-salmon;
  transform: rotate(180deg);
  margin-top: -2px;
}
</style>
