<template>
  <div class="data-component" v-if="reservations.length > 0">
    <p class="data-labels">{{ $t(`waitingListPropositions.table.expanded.${label}`) }}</p>
    <div class="d-flex justify-space-between" v-for="reservation in reservations" :key="reservation.id">
      <p class="start-data-day">{{ $t(`days.${reservation.dayOfWeek.toLowerCase()}`) }}</p>
      <p class="start-data-date">{{ getFormattedDate(reservation.validFrom) }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Placement, Reservation, ReservationStatus, ServiceVarietyName } from '@/models'
import { getFormattedDate } from '@/utils/dateUtils'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ServiceVarietyReservations extends Vue {
  @Prop({ required: true })
  placement!: Placement

  @Prop({ required: true })
  serviceVarietyName!: ServiceVarietyName

  @Prop({ required: true })
  label!: string

  ServiceVarietyName = ServiceVarietyName
  ReservationStatus = ReservationStatus

  orderedDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

  get reservations () {
    return this.getSortedReservationsForCareType(this.placement, this.serviceVarietyName)
  }

  getReservedOrOptionReservationsForPlacementByServiceVariety (placement: Placement, serviceVarietyName: ServiceVarietyName) : Reservation[] {
    return placement.reservations.filter(reservation =>
      reservation.serviceVariety.name === serviceVarietyName &&
      (reservation.status === ReservationStatus.Reserved || reservation.status === ReservationStatus.Option))
  }

  getSortedReservationsForCareType (placement: Placement, serviceVarietyName: ServiceVarietyName) : Reservation[] {
    const reservationsForCareType = this.getReservedOrOptionReservationsForPlacementByServiceVariety(placement, serviceVarietyName)
    reservationsForCareType.sort((a, b) => this.orderedDays.indexOf(a.dayOfWeek) - this.orderedDays.indexOf(b.dayOfWeek))
    return reservationsForCareType
  }

  getFormattedDate (date: Date, withTime = false): string {
    return getFormattedDate(date, withTime)
  }
}
</script>
