<template>
  <div class="d-flex flex-column data-component" v-if="showProductsInfo">
    <p class="data-labels">{{ $t('waitingListPropositions.table.expanded.products') }}</p>
    <div class="d-flex justify-space-between padding-bottom" v-for="product in products" :key="product.id">
      <p>{{ product.name }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Product } from '@/models'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ProductsInfo extends Vue {
  @Prop({ required: true })
  products!: Product[]

  @Prop({ required: true })
  showProductsInfo!: boolean
}
</script>
