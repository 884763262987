<template>
  <div class="days-data data-component">
    <template v-if="currentProposition">
      <template v-if="currentProposition.locationInquiry.booking.serviceKind === ServiceKind.SchoolCare">
        <div class="d-flex flex-column column-gap-4">
          <DayTableWaitingListProposition :serviceVarietyName="ServiceVarietyName.VSO" :proposition="currentProposition" />
          <DayTableWaitingListProposition :serviceVarietyName="ServiceVarietyName.NSO" :proposition="currentProposition" />
        </div>
      </template>
      <template v-else>
        <DayTableWaitingListProposition :serviceVarietyName="ServiceVarietyName.KDV" :proposition="currentProposition" />
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ServiceVarietyName, WaitingListProposition, ServiceKind } from '@/models'
import DayTableWaitingListProposition from './DayTableWaitingListProposition.vue'

@Component({
  components: {
    DayTableWaitingListProposition
  }
})
export default class SelectedDaysInfo extends Vue {
  @Prop({ required: true })
  currentProposition!: WaitingListProposition

  ServiceVarietyName = ServiceVarietyName
  ServiceKind = ServiceKind
}
</script>

<style scoped>
.days-data {
  overflow: auto;
  min-width: 270px;
}
</style>
