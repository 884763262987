// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".full-width{width:100%!important}.full-height{height:100%!important}.bold{font-weight:600!important}.bolder{font-weight:800!important}.float-left{float:left!important}.float-right{float:right!important}.cursor-pointer{cursor:pointer!important}.no-scroll{overflow:hidden!important}.align-middle{margin-left:auto;margin-right:auto}.cursor-grab{cursor:move!important;cursor:grab!important;cursor:-webkit-grab!important}.cursor-grab:active{cursor:grabbing!important;cursor:-webkit-grabbing!important}@media only screen and (min-width:960px){.mobile-only{display:none!important}}@media only screen and (max-width:959px){.desktop-only{display:none!important}}.row-gap-1{row-gap:4px}.row-gap-2{row-gap:8px}.row-gap-3{row-gap:12px}.row-gap-4{row-gap:16px}.row-gap-5{row-gap:20px}.row-gap-6{row-gap:24px}.row-gap-7{row-gap:28px}.row-gap-8{row-gap:32px}.column-gap-1{-moz-column-gap:4px;column-gap:4px}.column-gap-2{-moz-column-gap:8px;column-gap:8px}.column-gap-3{-moz-column-gap:12px;column-gap:12px}.column-gap-4{-moz-column-gap:16px;column-gap:16px}.column-gap-5{-moz-column-gap:20px;column-gap:20px}.column-gap-6{-moz-column-gap:24px;column-gap:24px}.column-gap-7{-moz-column-gap:28px;column-gap:28px}.column-gap-8{-moz-column-gap:32px;column-gap:32px}.visually-hidden{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.outline-on-focus:focus-visible{outline-color:#000;outline-width:2px;outline-style:solid}.outline-on-focus:focus:not(:focus-visible){outline:none}.revoke-reason-options .vs--searchable .vs__dropdown-menu .vs__dropdown-option{white-space:normal!important;overflow:unset!important;text-overflow:unset!important;height:unset!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"partouPrimaryPurple": "#582354",
	"partouPrimaryPurpleSixty": "#9b7b98",
	"partouPrimaryPurpleFourty": "#bca7bb",
	"partouPrimarySalmon": "#ef575d",
	"partouPrimarySalmonSixty": "#f59a9e",
	"partouPrimarySalmonFourty": "#f9bcbe",
	"partouPrimaryOrange": "#f6a500",
	"partouPrimaryOrangeSixty": "#fac966",
	"partouPrimaryOrangeFourty": "#fbdb99",
	"partouPrimaryWarmGrey": "#f2e9e2",
	"partouPrimaryWarmGreySixty": "#f7f2ee",
	"partouPrimaryWarmGreyFourty": "#faf6f3",
	"partouPrimaryWhite": "#fff",
	"partouPrimaryBackdropWhite": "#f9f9f9",
	"partouPrimaryBlue": "#2782e3",
	"partouSecondaryBordeaux": "#b61544",
	"partouSecondaryBordeauxSixty": "#d3738f",
	"partouSecondaryBordeauxFourty": "#e2a1b4",
	"partouSecondaryPeach": "#fa8870",
	"partouSecondaryPeachSixty": "#fcb8a9",
	"partouSecondaryPeachFourty": "#fdcfc6",
	"partouSecondaryGreen": "#91c37d",
	"partouSecondaryGreenSixty": "#bddbb1",
	"partouSecondaryGreenFourty": "#d3e7cb",
	"partouSecondaryBlue": "#73a4d8",
	"partouSecondaryBlueSixty": "#abc8e8",
	"partouSecondaryBlueFourty": "#c7dbef",
	"partouSecondaryBrightGreen": "#3bc059",
	"partouPrimaryGradient": "linear-gradient(45deg,#a02846 13.5%,#e8565d 87%)",
	"partouSecondaryGradient": "linear-gradient(61.41deg,#572556 7.85%,#eb5e5e 64.25%,#f7a70a 91.8%)",
	"partouPrimaryBlack": "#1d1d1b",
	"partouPrimaryBlackNinty": "#343432",
	"partouPrimaryBlackEighty": "#4a4a49",
	"partouPrimaryBlackSeventy": "#616160",
	"partouPrimaryBlackSixty": "#777776",
	"partouPrimaryBlackFifty": "#8e8e8d",
	"partouPrimaryBlackFourty": "#a5a5a4",
	"partouPrimaryBlackThirty": "#bcbcbb"
};
export default ___CSS_LOADER_EXPORT___;
