<template>
  <v-menu class="menu" v-bind="$attrs" v-on="$listeners">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <slot></slot>
  </v-menu>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class PartouMenu extends Vue {}
</script>

<style scoped>
  .v-menu__content {
    border-radius: 30px;
  }
</style>
