import { IDetailRepository } from '@/services'
import { IAuthService } from '@/services/AuthService/IAuthService'
import { Vue, Prop, Watch } from 'vue-property-decorator'
import AbstractComponent from '../../utils/AbstractComponent'

@AbstractComponent
export default abstract class BaseEntityDetail<TModel> extends Vue {
  @Prop({ required: true })
  public entityId!: string

  abstract repository: IDetailRepository<TModel>;
  abstract authService: IAuthService;

  entity?: TModel | null = null

  editEnabled = false
  isPageLoaded = false
  isSaving = false

  // Note: { immediate: true } does not seem to work with Jest
  @Watch('entityId', { immediate: true })
  async onEntityIdChangedAsync () : Promise<void> {
    this.editEnabled = false
    this.isPageLoaded = false
    this.entity = await this.getEntityAsync(this.entityId)

    this.isPageLoaded = true
  }

  // Note: hooks are used to bypass the issues you get when called super methods
  onEntityLoadedAsync (_entity: TModel): Promise<void> { return Promise.resolve() } // eslint-disable-line
  beforeUpsertEntityAsync (_entity: TModel): Promise<void> { return Promise.resolve() } // eslint-disable-line
  upsertEntityAsync (_entity: TModel): Promise<void> { return Promise.resolve() } // eslint-disable-line
  onEntityUpsertedAsync (_entity: TModel): Promise<void> { return Promise.resolve() } // eslint-disable-line
  onEditModeEnterAsync (_entity: TModel): Promise<void> { return Promise.resolve() } // eslint-disable-line
  onEditModeLeaveAsync (_entity: TModel): Promise<void> { return Promise.resolve() } // eslint-disable-line

  async getEntityAsync (id: string) : Promise<TModel> {
    const entity = await this.repository.getOneAsync(id)
    await this.onEntityLoadedAsync(entity)

    return entity
  }

  async onSaveClickedAsync () : Promise<void> {
    this.isSaving = true
    this.editEnabled = false

    if (this.entity) {
      await this.beforeUpsertEntityAsync(this.entity)
      await this.upsertEntityAsync(this.entity)
      await this.onEntityUpsertedAsync(this.entity)
      await this.onEditModeLeaveAsync(this.entity)
    }

    this.isSaving = false
  }

  async onEditClickedAsync () : Promise<void> {
    if (this.entity) {
      await this.onEditModeEnterAsync(this.entity)
    }

    this.editEnabled = true
  }

  async onCancelClickedAsync () : Promise<void> {
    this.editEnabled = false

    // Resets component's entity with the entity that is stored in Vuex
    // Does not fetch from backend
    this.entity = await this.getEntityAsync(this.entityId)
    await this.onEditModeLeaveAsync(this.entity)
  }
}
