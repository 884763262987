<template>
  <div>
    <div>
      <div class="d-flex pl-8 pt-4 pb-4 mb-6">
        <ServicePicker class="service-picker background-grey" v-model="tableOptions.filters.service" @input="onSelectedServiceChanged" :services="services"/>
        <PartouTextField v-model="tableOptions.filters.global" @input="onSearchChanged" :label="$t('table.searchLabel')" class="search ml-8 mt-5" :iconName="'$vuetify.icons.partouSearch'" :iconPosition="'right'" size="small"></PartouTextField>
      </div>
    </div>
    <PartouCard>
      <template slot="card-body">
        <PartouDataTable
          ref="partou-data-table"
          class="registration-table"
          :headers="headers"
          :items="items"
          :totalCount="totalCount"
          :showExpand="true"
          :tableOptions="tableOptions"
          :isLoading="isLoading"

          @expandedChanged="onExpandedChanged"
          @onOptionsChanged="onTableOptionsChangedAsync">
          <!-- custom column templates -->
          <template #item.child.fullName="{ item }" class="child-full-name"> <!-- eslint-disable-line -->
            <template v-if="item.id === expandedItem.id">
              <h3>
                {{ item.child && item.child.firstName }} {{ item.child && item.child.lastName }}
              </h3>
            </template>
            <template v-else>
              {{ item.child && item.child.firstName }} {{ item.child && item.child.lastName }}
            </template>
          </template>
          <template #item.child.dateOfBirth="{ item }"> <!-- eslint-disable-line -->
            {{ item.child && getFormattedDate(item.child.dateOfBirth) }}
          </template>
          <template #item.createdAt="{ item }"> <!-- eslint-disable-line -->
            {{ getFormattedDate(item.createdAt, true) }}
          </template>
          <template #item.validFrom="{ item }"> <!-- eslint-disable-line -->
            {{ getFormattedDate(item.validFrom) }}
          </template>
          <template #item.location="{ item }"> <!-- eslint-disable-line -->
           {{ getLocationName(item.service) }}
          </template>
          <template #item.reservations="{ item }"> <!-- eslint-disable-line -->
            <div v-html="getStatusOfDayOfWeek(item.placements, item.locationInquiries)"/>
          </template>
          <template #item.status="{ item }"> <!-- eslint-disable-line -->
            {{ getCurrentBookingStatus(item.bookingType, item.bookingStatus) }}
          </template>

          <!-- expand template -->
          <template v-slot:expanded-item="{ headers }">
            <td class="pa-0" :colspan="headers.length">
              <div v-if="isLoading" class="d-flex justify-center flex-row">
                <v-progress-circular indeterminate></v-progress-circular>
              </div>
              <div v-else>
                <span class="expanded-divider"></span>
                <RegistrationTableExpanded :booking="expandedItem" :waitinglistPlaces="getExpandedWaitingListPlaces(expandedItem)" :reservations="getExpandedReservations(expandedItem)" :serviceProducts="serviceProducts" />
                <span class="d-none float-right">Boeking id: {{ expandedItem.id }}</span>
                <span class="d-none float-right">Reservering ids: {{ getReservationIds(expandedItem.reservations) }} </span>
              </div>
            </td>
          </template>
        </PartouDataTable>
      </template>
    </PartouCard>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Ref } from 'vue-property-decorator'
import { DataTableHeader } from '@/components/PartouComponents/PartouDataTable'
import PartouDataTable from '@/components/PartouComponents/PartouDataTable/PartouDataTable.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import RegistrationTableExpanded from './Expanded/RegistrationTableExpanded.vue'
import { Booking, BookingStatus, DayOfWeek, LocationInquiry, Placement, Reservation, Service, ServiceProduct, WaitingListPlace } from '@/models'
import ServicePicker from '@/components/ServicePicker'
import DaysCoverage from '@/components/DaysCoverage/DaysCoverage.vue'
import BaseEntityTable from '@/pages/_base/BaseEntityTable'
import RegistrationTableOptions from './RegistrationTableOptions'
import PartouTextField from '@/components/PartouComponents/PartouTextField.vue'
import { getShortenedDayName, parseStringToDayOfWeek } from '@/models/enums/DayOfWeek'
import BookingType from '@/models/enums/BookingType'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { IProductService } from '@/services/ProductService/IProductService'
import { getFormattedDate } from '@/utils/dateUtils'

@Component({
  components: { ServicePicker, PartouCard, PartouDataTable, DaysCoverage, RegistrationTableExpanded, PartouTextField }
})
export default class RegistrationTable extends BaseEntityTable<Booking> {
  productService: IProductService = container.get<IProductService>(SERVICE_IDENTIFIERS.IProductService)

  @Prop({ required: true })
  items!: Partial<Booking>[]

  @Prop({ required: true })
  services!: Service[]

  @Prop({ required: true, default: () => { return {} } })
  expandedItem! : Booking

  @Ref('partou-data-table')
  partouDataTable!: PartouDataTable<Booking>

  serviceProducts: Partial<ServiceProduct>[] = []

  headers: DataTableHeader[] = [
    { text: 'registrations.table.columns.childName', value: 'child.fullName', sortBy: 'child.fullName', width: '14%' },
    { text: 'registrations.table.columns.dateOfBirth', value: 'child.dateOfBirth', sortBy: 'child.dateOfBirth', width: '12%' },
    { text: 'registrations.table.columns.createdAt', value: 'createdAt', sortBy: 'createdAt', width: '11.5%' },
    { text: 'registrations.table.columns.validFrom', value: 'validFrom', sortBy: 'validFrom', width: '11.5%' },
    { text: 'registrations.table.columns.location', value: 'location', sortBy: 'service.name', width: '11.5%' },
    { text: 'registrations.table.columns.days', value: 'reservations', sortBy: 'service.name', width: '23%' },
    { text: 'registrations.table.columns.status', value: 'status', sortBy: 'bookingStatus.status', width: '11.5%' },
    { text: '', value: 'data-table-expand', width: '5%' }
  ]

  tableOptions : RegistrationTableOptions = {
    sortBy: ['createdAt'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 50,
    itemsPerPageOptions: [5, 10, 25, 50, 100],
    filters: {}
  }

  async mounted () : Promise<void> {
    this.onTableOptionsChangedAsync(this.tableOptions)
    await this.getServiceProductsAsync()
  }

  getFormattedDate (date: Date, withTime = false): string {
    return getFormattedDate(date, withTime)
  }

  getLocationName (service: Service) : string {
    return service.name
  }

  getCurrentBookingStatus (bookingType : BookingType, status : BookingStatus) : string {
    if (bookingType === BookingType.Empty || status.isCancelled) {
      return this.$t('registrations.table.status.cancelled').toString()
    }

    if (status.isCompleted) {
      return this.$t('registrations.table.status.completed').toString()
    }

    return this.$t('registrations.table.status.pending').toString()
  }

  getReservationIds (reservations: Reservation[]):string {
    return reservations?.map(reservation => reservation.id).join(', ') ?? ''
  }

  getStatusOfDayOfWeek (placements: Placement[], locationInquiries: LocationInquiry[]) : string {
    const reservations = placements.flatMap(x => x.reservations)
    const waitinglistplaces = locationInquiries.flatMap(x => x.waitingListPlaces)
    const combinedList: Record<DayOfWeek, string> = { Monday: '', Tuesday: '', Wednesday: '', Thursday: '', Friday: '', Saturday: '', Sunday: '' }
    const waitinglistDays = new Set(waitinglistplaces.flatMap(waitinglistPlace => parseStringToDayOfWeek(waitinglistPlace.dayOfWeek)))
    const reservationDays = new Set(reservations.flatMap(reservation => parseStringToDayOfWeek(reservation.dayOfWeek)))

    waitinglistDays.forEach(day => {
      combinedList[day] = `<span class="waitinglistPlace"><b>${this.firstToUpper(getShortenedDayName(day))}</b></span> `
    })
    reservationDays.forEach(day => {
      combinedList[day] = `<span class="placement"><b>${this.firstToUpper(getShortenedDayName(day))}</b></span> `
    })

    return Object.values(combinedList).join('')
  }

  async getServiceProductsAsync () : Promise<void> {
    for (const booking of this.items) {
      if (!booking || !booking.locationInquiries) {
        continue
      }

      const waitingListPlaces = booking.locationInquiries.flatMap(x => x.waitingListPlaces)
      for (const waitingListPlace of waitingListPlaces) {
        const product = waitingListPlace.product
        if (!product) {
          continue
        }
        const serviceId = booking.serviceId ?? ''
        const serviceProduct = await this.productService.getSingleServiceProductAsync(serviceId, product.id)
        if (serviceProduct && !this.serviceProducts.some(serviceProduct => serviceProduct.productId === product.id && serviceProduct.serviceId === serviceId)) {
          this.serviceProducts.push(serviceProduct)
        }
      }
    }
  }

  firstToUpper (dayLabel: string):string {
    return dayLabel[0].toUpperCase() + dayLabel.substring(1)
  }

  @Emit('onExpandedChanged')
  onExpandedChanged (items: Partial<Booking>[]) : Partial<Booking>[] {
    return items
  }

  getExpandedWaitingListPlaces (booking : Booking) : WaitingListPlace[] {
    return booking?.locationInquiries?.flatMap(x => x.waitingListPlaces) ?? []
  }

  getExpandedReservations (booking : Booking) : Reservation[] {
    return booking?.placements?.flatMap(x => x.reservations) ?? []
  }

  onSelectedServiceChanged () : void {
    this.partouDataTable.onFilterChanged()
  }

  onSearchChanged () : void {
    this.partouDataTable.onFilterChanged()
  }
}
</script>

<style lang="scss">
@import '@/styles/variables/variables.scss';
  .waitinglistPlace b{
    color: $partou-yellow;
  }

  .placement b{
    color: $partou-green;
  }

  .search {
    .v-input__control .v-input__slot {
      background: transparent !important;
      box-shadow: none;
      border: 1px solid #BCBCBB !important;
    }

    .v-input__slot::before {
      display:none;
    }
    .v-input__slot::after {
      display:none;
    }
    .v-text-field__details {
      display: none;
    }
    .v-text-field__slot {
      display: none;
    }
  }
</style>
<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

  .search {
    max-width: 300px !important;
    width: 300px !important;
    margin-top: 9px;
    .v-text-field__slot {
      input {
        color: $partou-primary-black-seventy;
        font-weight: bold;
      }
    }
  }
  .registration-table {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .expanded-divider {
    background-color: $partou-primary-salmon-sixty;
    height: 1px;
    display: flex;
  }

  .child-full-name {
    color: $partou-primary-black-ninety;
  }

  h3 {
    color: $partou-primary-black-ninety;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
  }
</style>
