<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.25325 1.01306H2.1775C1.86866 1.01306 1.57246 1.13575 1.35407 1.35414C1.13569 1.57252 1.013 1.86872 1.013 2.17756L1.013 13.8226C1.013 14.1314 1.13569 14.4276 1.35407 14.646C1.57246 14.8644 1.86866 14.9871 2.1775 14.9871H13.8225C14.1313 14.9871 14.4275 14.8644 14.6459 14.646C14.8643 14.4276 14.987 14.1314 14.987 13.8226V9.74681" :stroke="color" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.70235 8.44903C6.46804 8.68334 6.46804 9.06324 6.70235 9.29756C6.93667 9.53187 7.31657 9.53187 7.55088 9.29756L6.70235 8.44903ZM15.587 1.01292C15.587 0.681545 15.3184 0.412916 14.987 0.412916H9.58699C9.25562 0.412916 8.98699 0.681545 8.98699 1.01292C8.98699 1.34429 9.25562 1.61292 9.58699 1.61292H14.387V6.41292C14.387 6.74429 14.6556 7.01292 14.987 7.01292C15.3184 7.01292 15.587 6.74429 15.587 6.41292V1.01292ZM7.55088 9.29756L15.4113 1.43718L14.5627 0.588653L6.70235 8.44903L7.55088 9.29756Z" :fill="color"/>
    </svg>
</template>
<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class PartouOpenInNew extends Vue {
  @Prop({ required: true })
  color!: string
}
</script>
