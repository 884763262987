<template>
  <v-toolbar height="80px" class="menu" :class="{
    'menu-dev': currentEnv === 'dev',
    'menu-test': currentEnv === 'test',
    'menu-demo': currentEnv === 'demo',
    'menu-acc': currentEnv === 'acc'
  }">
    <!-- mobile menu items -->
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon v-bind="attrs" v-on="on" class="hidden-md-and-up"></v-app-bar-nav-icon>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in allowedMenuItems" :key="index" :to="item.link">
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- app title -->
    <div class="logo-container">
        <img alt="" class="img mt-8 col-12 logo" src="@/assets/images/partou-logo-gradient.svg" @click="logoClicked"/>
    </div>

    <!-- desktop menu items -->
    <v-toolbar-items class="ml-4 hidden-sm-and-down menu-items">
        <v-btn :ripple="false" v-for="(item, index) in allowedMenuItems" :key="index" :to="item.link" class="menu-item">{{ $t(item.title) }}</v-btn>
    </v-toolbar-items>

    <v-spacer></v-spacer>

    <!-- desktop logout button -->
    <v-btn :ripple="false" rounded class="hidden-sm-and-down logout" @click="authService.logoutAsync()">{{$t('application.logout')}}</v-btn>

    <!-- mobile logout icon -->
    <v-btn icon class="hidden-md-and-up red-text mobile-logout">
      <v-icon @click="authService.logoutAsync()">mdi-logout</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { IAuthService } from '@/services/AuthService/IAuthService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import Permission from '@/models/enums/Permission'
import { ROUTES } from '@/router/routes'

type MenuItem = { title: string, link: string }

@Component
export default class AppMenu extends Vue {
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  get currentEnv () : string | undefined {
    const hostname = window.location.hostname ?? ''
    if (hostname.includes('localhost')) { return 'dev' }
    if (hostname.includes('backoffice.test')) { return 'test' }
    if (hostname.includes('backoffice.demo')) { return 'demo' }
    if (hostname.includes('backoffice.acc')) { return 'acc' }
    return ''
  }

  get allowedMenuItems (): MenuItem[] {
    const allowedMenuItems: MenuItem[] = []

    if (this.authService.hasPermission([Permission.GetService])) {
      allowedMenuItems.push({ title: 'application.menu.locations', link: '/services' })
    }

    if (this.authService.hasPermission([Permission.GetBooking])) {
      allowedMenuItems.push({ title: 'application.menu.reservations', link: '/inschrijvingen' })
    }

    if (this.authService.hasPermission([Permission.GetWaitingList])) {
      allowedMenuItems.push({ title: 'application.menu.waitingListPropositions', link: '/wachtlijst-aanbiedingen' })
    }

    if (this.authService.hasPermission([Permission.GetAnalysis])) {
      allowedMenuItems.push({ title: 'application.menu.analysis', link: '/analyse/service' })
    }

    if (this.authService.hasPermission([Permission.CallActions, Permission.GetSetting, Permission.ManageSetting])) {
      allowedMenuItems.push({ title: 'application.menu.settings', link: '/instellingen' })
    }

    return allowedMenuItems
  }

  logoClicked ():void {
    if (this.$route.name !== ROUTES.home) {
      this.$router.push({ name: ROUTES.home })
    }
  }

  menuItemClicked (link: string) : void {
    this.$router.push(link)
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/breakpoints.scss';
@import '@/styles/variables/variables.scss';

.v-btn {
  text-transform: none !important;
  box-shadow: none;
}

.menu {
  z-index: 10;
  background-color: $partou-primary-white !important;

  .logout {
    color: $partou-primary-white;
    background-color: $partou-primary-salmon !important;
    margin-right: 96px;
  }

  .logo-container {
    padding-left: 105px !important;
    padding-right: 69px !important;

    .logo {
      padding-left: 0 !important;
      padding-right: 0 !important;
      cursor: pointer;
    }
  }

  .menu-items {
    gap: 42px;

    .menu-item {
      padding: 0 0 14px 0;
      height: 34px !important;
      background: none !important;
      line-height: 100px !important;
      margin-top: 46px !important;

      &:before {
        display: none !important;
      }

      &.v-btn--active{
        border-bottom: 3px solid $partou-primary-salmon;
        height: 37px !important;
      }
    }
  }
}

.menu-dev { background-color: $partou-primary-orange !important; }
.menu-dev::before { content: 'dev'; color: $partou-primary-orange-fourty; position: absolute; top: 5px; left: 5px; }
.menu-test { background-color: $partou-secondary-peach !important; }
.menu-test::before { content: 'test'; color: $partou-secondary-peach-fourty; position: absolute; top: 5px; left: 5px; }
.menu-demo { background-color: $partou-secondary-bordeaux !important; }
.menu-demo::before { content: 'demo'; color: $partou-secondary-bordeaux-fourty; position: absolute; top: 5px; left: 5px; }
.menu-acc { background-color: $partou-primary-purple !important; }
.menu-acc::before { content: 'acc'; color: $partou-primary-purple-fourty; position: absolute; top: 5px; left: 5px; }

.menu-dev, .menu-test, .menu-demo, .menu-acc {
  .menu-item {
    color: $partou-primary-white;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .menu {
      .logo-container {
        padding-left: 0 !important;
        padding-right: 0 !important;

        .logo {
          width: 105px;
          height: 100px;
        }
    }

    .mobile-logout {
      color: $partou-primary-salmon !important;
    }
  }
}
</style>
