<template>
  <div class="button-row">
    <PartouButton
      v-if="showProposeActions()"
      class="cancel-button"
      :text="$t('waitingListPropositions.table.expanded.cancel')"
      :size="'normal'"
      colour="partou-black-ninety"
      @click="onCancelClicked"
    />
    <v-btn
      v-if="showProposeActions()"
      :disabled="!canPropose"
      :ripple="false"
      rounded
      class="propose-button"
      @click="onProposeClicked"
    >
      {{ $t('waitingListPropositions.table.expanded.propose') }}
    </v-btn>
    <PartouButton
      v-if="showRevokeActions()"
      class="revoke-proposition-button"
      :text="$t('waitingListPropositions.table.expanded.revoke')"
      :size="'normal'"
      colour="partou-black-ninety"
      @click="onRevokeClicked"
    />
    <PartouFloatingCircleButton
      ref="analysis-button"
      class="analysis-button"
      icon-name="$vuetify.icons.partouAnalysis"
      @click="onGoToAnalysisResultPageClick"
    />
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/PartouButton.vue'
import PartouFloatingCircleButton from '@/components/PartouComponents/PartouFloatingCircleButton.vue'
import { WaitingListProposition } from '@/models'
import { ROUTES } from '@/router/routes'
import WaitingListPropositionStatus from '@/models/enums/WaitingListPropositionStatus'

@Component({
  components: {
    PartouButton,
    PartouFloatingCircleButton
  }
})
export default class ActionButtons extends Vue {
  @Prop({ required: true })
  canManageWaitingList!: boolean

  @Prop({ required: true })
  currentProposition!: WaitingListProposition

  @Prop({ required: true })
  canPropose!: boolean

  WaitingListPropositionStatus = WaitingListPropositionStatus

  showProposeActions () : boolean {
    return this.canManageWaitingList && this.currentProposition.status === WaitingListPropositionStatus.Created
  }

  showRevokeActions () : boolean {
    return this.canManageWaitingList && this.currentProposition.status === WaitingListPropositionStatus.Proposed
  }

  @Emit('cancel-clicked')
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onCancelClicked () {}

  @Emit('propose-clicked')
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onProposeClicked () {}

  @Emit('revoke-clicked')
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onRevokeClicked () {}

  onGoToAnalysisResultPageClick () : void {
    if (this.currentProposition.locationInquiry.booking.serviceId == null) {
      throw new Error(`Booking with id ${this.currentProposition.locationInquiry.booking.id} contains no service_id`)
    }

    const routeData = this.$router.resolve({ name: ROUTES.serviceWaitingListPropositionAnalysisNew, params: { serviceId: this.currentProposition.locationInquiry.booking.serviceId } })
    window.open(routeData.href, '_blank')
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
@import '@/styles/helpers.scss';

.button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.propose-button {
  color: $partou-primary-white;
  background-color: $partou-primary-salmon !important;
  text-transform: none !important;
}

.analysis-button {
  width: 35px;
  height: 35px;
  button span svg {
    path {
      fill: $partou-primary-black-ninety !important;
      color: $partou-primary-black-ninety !important;
    }
  }
}

.cancel-button {
  height: 36px;
}

.button-row > * {
  margin-right: 16px;
}

.button-row > *:last-child {
  margin-right: 0;
}
</style>
