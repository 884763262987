<template>
 <div>
    <h1 class="pl-8 pt-4">{{ $t('waitingListPropositions.table.heading') }}</h1>
    <WaitingListPropositionTable
      :items="items"
      :expandedItem="expandedItem"
      :totalCount="totalCount"
      :services="services"
      :isLoading="isLoading"
      :serviceProducts="serviceProducts"
      @onTableOptionsChanged="onTableOptionsChangedAsync"
      @onExpandedChanged="onExpandedChangedAsync">
    </WaitingListPropositionTable>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import { Service, ServiceProduct, WaitingListProposition } from '@/models'
import ServicePicker from '@/components/ServicePicker'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import Debounce from '@/utils/decorators/debounceDecorator'
import WaitingListPropositionTable from './WaitingListPropositionTable/WaitingListPropositionTable.vue'
import { namespace } from 'vuex-class'
import { ACTIONS, NAMESPACES, STATE } from '@/store'
import WaitingListPropositionTableOptions from './WaitingListPropositionTable/WaitingListPropositionTableOptions'
import { IPropositionService } from '@/services/PropositionService/IPropositionService'
import { IProductService } from '@/services/ProductService/IProductService'

const serviceModule = namespace(NAMESPACES.service)

@Component({
  components: { ServicePicker, PartouCard, WaitingListPropositionTable }
})
export default class WaitingListOfferPage extends Vue {
  propositionService: IPropositionService = container.get<IPropositionService>(SERVICE_IDENTIFIERS.IPropositionService)
  productService: IProductService = container.get<IProductService>(SERVICE_IDENTIFIERS.IProductService)

  @serviceModule.State(STATE.service.services) services!: Service[]
  @serviceModule.Action(ACTIONS.service.getServices) getServices!: () => void

  async created (): Promise<void> {
    this.getServices()
  }

  items: Partial<WaitingListProposition>[] = []
  expandedItem? : Partial<WaitingListProposition> = {}
  totalCount = 0
  isLoading = false
  serviceProducts: Partial<ServiceProduct>[] = []

  @Debounce(400)
  async onTableOptionsChangedAsync (tableOptions : WaitingListPropositionTableOptions) : Promise<void> {
    this.isLoading = true
    const result = await this.propositionService.getWaitingListPropositionsPageAsync(tableOptions)
    this.items = result.items
    this.totalCount = result.totalCount
    await this.getServiceProductsAsync()
    this.expandedItem = {}
    this.isLoading = false
  }

  async onExpandedChangedAsync (items : Partial<WaitingListProposition>[]) : Promise<void> {
    this.expandedItem = {}
    if (items?.[0]?.id) {
      this.isLoading = true
      this.expandedItem = await this.propositionService.getOnePropositionAsync(items[0].id)
      this.isLoading = false
    }
  }

  async getServiceProductsAsync () : Promise<void> {
    for (const proposition of this.items) {
      if (!proposition || !proposition.placement) {
        continue
      }

      for (const reservation of proposition.placement.reservations) {
        const product = reservation.product
        if (!product) {
          continue
        }

        const serviceId = proposition.locationInquiry?.booking.serviceId ?? ''
        const serviceProduct = await this.productService.getSingleServiceProductAsync(serviceId, product.id)
        if (serviceProduct && !this.serviceProducts.some(serviceProduct => serviceProduct.productId === product.id && serviceProduct.serviceId === serviceId)) {
          this.serviceProducts.push(serviceProduct)
        }
      }
    }
  }
}
</script>
